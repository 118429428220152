import { render, staticRenderFns } from "./exaLeave.vue?vue&type=template&id=40dab55b&scoped=true&"
import script from "./exaLeave.vue?vue&type=script&lang=js&"
export * from "./exaLeave.vue?vue&type=script&lang=js&"
import style0 from "./exaLeave.vue?vue&type=style&index=0&id=40dab55b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40dab55b",
  null
  
)

export default component.exports