<template>
  <div>
    <van-nav-bar
      title="请假审批"
      left-text="返回"
      @click-left="back"
      fixed
      left-arrow
    />
    
    <div class="content">
        <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh">
            <van-list v-model="listLoading" :finished="finished" finished-text="已全部加载完成" @load="touchBottom">
                <van-cell class="item" v-for="item in showList" :key="item.id">
                <div class="item_text">
                    <div class="text_list">
                        <div class="text_left">申请人</div>
                        <div class="text_right">
                            {{item.ownerName}}
                            <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.ownerName'></TranslationOpenDataText></span>
                            <span v-else>{{item.ownerName}}</span>
                        </div>
                    </div>
                    <div class="text_list">
                        <div class="text_left">所在部门</div>
                        <div class="text_right">
                            {{item.dept}}
                            <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='departmentName' :openid='item.dept'></TranslationOpenDataText></span>
                            <span v-else>{{item.dept}}</span>
                        </div>
                    </div>
                    <div class="text_list">
                        <div class="text_left">请假类型</div>
                        <div class="text_right">{{qjType[item.leaveType]}}</div>
                    </div>
                    <div class="text_list">
                        <div class="text_left">请假时间</div>
                        <div class="text_right">{{item.indate[0] + '-' + item.indate[1] + '-' + item.indate[2]}}</div>
                    </div>
                    <div class="text_list">
                        <div class="text_left">请假时长</div>
                        <div class="text_right">{{item.timeType ? item.timeHours + ' 小时' : item.timeDays + ' 天'}}</div>
                    </div>
                </div>
                <div class="item_ope" v-if="item.status == 1">
                    <van-button type="info" size="small" @click="agreeExa(item)">同意</van-button>
                    <van-button type="danger" size="small" @click="rejectExa(item)">驳回</van-button>
                </div>
                <div class="item_ope" v-if="item.status != 1">
                    <span :class="item.status == 0 ? 'ope_agree' : 'ope_reject'">{{item.status == 0 ? "已同意" : "已驳回"}}</span>
                </div>
                </van-cell>
            </van-list>
            
        </van-pull-refresh>
        
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
    data() {
        return {
            user: JSON.parse(localStorage.userInfo),
            refreshLoading: false,
            listLoading: false,
            finished: false,
            showList:[],
            pageIndex: 1,
            qjType: ['事假','病假','年假','产假','婚假','丧假','调休假','陪产假','其他']
        }
    },
    mounted(){
        this.getList()
    },
    methods: {
        back() {
            history.back();
        },
        // 下拉刷新
        onRefresh(){
            this.showList = []
            this.finished = false
            this.pageIndex = 1
            setTimeout(() => {
                Toast('刷新成功')
                this.refreshLoading = false
                this.getList()
            },600)
        },
        // 触底加载
        touchBottom(){
            this.pageIndex += 1
            this.getList()
        },
        // 同意
        agreeExa(item){
            const toast = this.$toast.loading({forbidClick: true, duration: 0});
            this.$axios.post('/leave-sheet/approve',{
                id: item.id
            }).then(res => {
                if(res.code == 'ok'){
                    this.$toast.clear()
                    item.status = 0
                }else {
                    this.$toast.clear()
                    this.$toast.fail('操作失败')
                }
            }).catch(err => {
                this.$toast.clear()
                this.$toast.fail(err)
            })
        },
        // 驳回
        rejectExa(item){
             const toast = this.$toast.loading({forbidClick: true, duration: 0});
            this.$axios.post('/leave-sheet/deny',{
                id: item.id
            }).then(res => {
                if(res.code == 'ok'){
                    this.$toast.clear()
                    item.status = 3
                }else {
                    this.$toast.clear()
                    this.$toast.fail('操作失败')
                }
            }).catch(err => {
                this.$toast.clear()
                this.$toast.fail(err)
            })
        },
        // 获取待审核列表
        getList(){
            const toast = this.$toast.loading({forbidClick: true, duration: 0});
            this.$axios.post('/leave-sheet/list',{
                pageIndex: this.pageIndex,
                pageSize: 10,
                status: 1,
                createDate: '',
                ownereId: '',
                leaveType: ''
            }).then(res => {
                if(res.code == 'ok'){
                    this.$toast.clear();
                    this.showList = res.data.records
                    if(res.data.records.length > 0){
                        let list = res.data.records
                        for(let i=0; i<list.length; i++){
                            this.showList.push(list[i])
                        }
                        this.listLoading = false
                    }else {
                        this.listLoading = false
                        this.finished = true
                    }
                }else {
                    this.$toast.clear();
                    this.$toast.fail('获取失败')
                }
            }).catch(err => {
                this.$toast.clear();
                this.$toast.fail(err);
            })
        }
    },
}
</script>

<style lang="less" scoped>
    .content{
        margin-top: 46px;
        overflow: auto;
        .item{
            margin-bottom: 0.35rem;
            background-color: #fff;
            padding: 0.26667rem 0.58667rem 0.21333rem;
            .item_text{
                font-size: 0.37333rem;
                border-bottom: 1px solid #ebedf0;
                padding-bottom: 0.21333rem;
                .text_list{
                    line-height: 0.8rem;
                }
            }
            .item_ope{
                padding-top: 0.21333rem;
                text-align: right;
                font-size: 0.37333rem;
                width: 100%;
                height: 1.2rem;
                display: flex;
                justify-content: right;
                align-items: center;
                .van-button{
                    margin-left: 0.26667rem;
                }
                .ope_agree{
                    
                    color: #1989fa;
                    margin-right: 0.4rem;
                }
                .ope_reject{
                    
                    color: #c03131;
                    margin-right: 0.4rem;
                }
            }
            .text_left{
                float: left;
                width: 33%;
            }
        }
    }
</style>